import styled, { css } from "styled-components";
import { Popup } from "devextreme-react/popup";

export const Container = styled.div`
  position: relative;
  flex: 1;
  height: fit-content;
  width: 100%;

  & > div {
    box-shadow: 0 0 30px rgba(0, 0, 0, 7%);
    border-radius: 7px;
    overflow: hidden;
  }
  .dx-datagrid {
    color: #333;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
    border: none;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    color: #999;
    font-size: 0.75rem;
    font-weight: 300;
  }

  .dx-datagrid-rowsview .dx-row {
    cursor: pointer;

    :hover {
      background-color: #f5f5f5ff;
    }
    :active {
      background-color: #d9d9d9ff;
    }
  }

  .dx-datagrid-rowsview .dx-row.dx-freespace-row {
    cursor: default;

    :hover {
      background-color: transparent;
    }
    :active {
      background-color: transparent;
    }
  }

  .dx-datagrid .dx-row > td {
    font-size: 0.75rem;
    font-weight: 400;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid #f5f5f5;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border: none;
  }

  .dx-data-row > td {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 0;
    font-size: 1.5rem;
    border: none;
    box-shadow: 0 0 30px rgba(0 0 0 / 15%);
    background: #8b0304;
    color: white;

    :hover {
      background: #b80304;
    }
    :active {
      background: #a80304;
    }
  }
  .office-icon {
    width: 24px;
    height: 24px;
  }
`;

export const GradeCell = styled.div`
  font-size: 1rem;
  color: #333;
  font-weight: 700;
`;

const statusesColors = {
  1: "#ccc",
  2: "#d15c56",
  3: "#edce61",
  4: "#52b9d9",
  5: "#4cb56f",
  6: "#666",
  7: "#8b0304",
  true: "#4cb56f",
  false: "#d15c56",
  undefined: "#ccc",
};

const promotionStatusesColors = {
  1: "#ffc107",
  2: "#F44336",
  3: "#4CAF50",
  4: "#673AB7",
  5: "#00BCD4",
  6: "#795548",
  7: "#4CAF50",
};

export const StatusCell = styled.div`
  ${(props) => css`
    background-color: ${statusesColors[props.status]};
  `};
  display: flex;
  width: auto;
  padding-right: 0px;
  align-items: center;
  gap: 0.3125rem;
  flex-shrink: 0;
  border-radius: 18.75rem;
  color: #fff;

  font-family: Roboto Condensed;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;

  & img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 3px;
    filter: grayscale(1) brightness(0.95);
  }
`;

export const PromotionStatusCell = styled.div`
  ${(props) => css`
    background-color: ${promotionStatusesColors[props.status]};
  `};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 300px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  gap: 5px;

  & img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 3px;
    filter: grayscale(1) brightness(0.95);
  }
`;
export const Photo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
`;

export const StyledPopup = styled(Popup)`
  .dx-popup-content {
    padding: 0;
    display: flex;
    flex-direction: column;

    .buttons {
      justify-content: flex-end;
      display: flex;
      gap: 10px;
      padding: 30px;
    }
  }

  .dx-button-success .dx-button-content,
  .dx-button-mode-text .dx-button-content {
    white-space: nowrap;
    padding: 0.5em 1em;
    background-color: #8b0304;
    color: white;
    min-width: 6rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.1em;
  }

  .dx-button-success:hover .dx-button-content,
  .dx-button-mode-text .dx-button-content:hover {
    background-color: #b80304;
  }

  .dx-button-mode-text.dx-state-disabled .dx-button-content {
    background-color: #ccc;
    color: #333;
  }

  .alerts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px;
  }

  .custom-scroll .dx-scrollable-wrapper {
    padding: 30px;
  }

  .alert {
    display: flex;
    padding: 15px 20px;
    font-size: 0.875rem;
    border-radius: 7px;
    align-items: center;
    margin: 0;
    gap: 10px;
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);

    ul {
      margin-left: 15px;
    }

    span {
      font-weight: 700;
    }

    svg {
      display: flex;
      opacity: 0.9;
      margin-right: 12px;
    }
  }

  .alert.important {
    background-color: #fff3cd;
    color: #856404;
  }

  .grid {
    box-shadow: 0 0 30px rgba(0, 0, 0, 10%);
    border-radius: 7px;
    overflow: hidden;
    height: 100%;

    .dx-datagrid {
      color: #333;
    }

    .dx-row.dx-group-row {
      background: white !important;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      border: none;
      border-radius: 7px;
    }

    .dx-editor-cell:first-of-type > .dx-texteditor .dx-texteditor-input {
      padding-left: 0.8rem !important;
    }

    .dx-datagrid-headers.dx-datagrid-nowrap {
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.07);
      border: none;
    }

    .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
      color: #999;
      font-size: 0.75rem;
      font-weight: 300;
    }

    /* .dx-datagrid-rowsview .dx-row {
    cursor: pointer;

    :hover {
      background-color: #f5f5f5ff;
    }
    :active {
      background-color: #d9d9d9ff;
    }
  } */

    .dx-datagrid-rowsview .dx-row.dx-freespace-row {
      cursor: default;

      :hover {
        background-color: transparent;
      }
      :active {
        background-color: transparent;
      }
    }

    .dx-datagrid .dx-row > td {
      font-size: 0.75rem;
      font-weight: 400;
      color: #333;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .dx-datagrid .dx-row-lines > td {
      border-bottom: 1px solid #f5f5f5;
    }

    .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
      border: none;
    }

    .dx-data-row > td {
      position: relative;
    }
  }
`;
