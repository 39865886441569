import DataGrid, { Column, Lookup } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import pdsGroups from "../../config/pdsGroups";
import { ValueContext } from "../../context/ValueProvider";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import DialogEditProject from "../../pages/Evaluation/Seniors/DialogEditProject";
import api from "../../services/api";
import OfficeIcon from "../OfficeIcons";
import PhotoCell from "./CellRender/PhotoCell";
import CustomEditInsertPopup from "./Dialog/CustomEditInsertPopup";
import { Container, GradeCell, StatusCell } from "./styles";

export default function MainList({ path }) {
  const { addToast } = useToast();
  const { user, hasScope } = useAuth();
  const isAdmin = useMemo(() => hasScope([pdsGroups.Admin]), [hasScope]);
  const isSales = useMemo(() => hasScope([pdsGroups.Sales]), [hasScope]);
  const isSenior = useMemo(() => {
    return hasScope([pdsGroups.Manager, pdsGroups.Director, pdsGroups.Partner]);
  }, [hasScope]);
  const history = useHistory();
  const [openEditProject, setOpenEditProject] = useState(false);
  const [evaluationEditProjectId, setEvaluationEditProjectId] = useState();
  const [evaluationConfirmedProject, setEvaluationConfirmedProject] =
    useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const { master } = useContext(ValueContext);

  const dataSource = useMemo(() => {
    return new DataSource({
      load: async () => {
        try {
          if (path === "toevaluate") {
            const { data } = await api.get(`/api/evaluations/to-evaluate`);
            const filteredData = data.filter(
              (x) => x.idProfessional !== user.userId
            );
            return filteredData;
          }
          if (path === "allevaluations") {
            const { data } = await api.get("/api/evaluations/all-by-user", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });
            return data;
          }
          if (path === "myevaluations") {
            const { data } = await api.get("/api/evaluations/self-evaluations");
            const enrichedData = data.map((item) => {
              const shouldDisplayDash =
                (item.idProfessional === user.userId &&
                  item.idEvaluationStatus !== 4 &&
                  item.idEvaluationStatus !== 5) ||
                (isSales && item.idProfessional !== user.userId) ||
                (isSales &&
                  item.idProfessional === user.userId &&
                  item.idEvaluationStatus !== 4 &&
                  item.idEvaluationStatus !== 5);

              return {
                ...item,
                displayedAverage: shouldDisplayDash
                  ? "-"
                  : item.totalAverage !== null
                  ? parseFloat(item.totalAverage).toFixed(2)
                  : "-",
              };
            });

            return enrichedData;
          }
        } catch (err) {
          addToast({
            type: "error",
            title: "Evaluations couldn't be loaded. Try again later",
          });
          throw err;
        }
      },
      insert: async (values) => {
        try {
          await api.post("/api/evaluations", values);
        } catch (err) {
          addToast({
            type: "error",
            title: "Error inserting evaluation",
          });
          throw err;
        }
      },
      update: async (key, values) => {
        try {
          await api.put(`/api/evaluations/${key}`, {
            ...values,
            idOldEvaluator: values.evaluator?.id,
            idOldValidator: values.validator?.id,
          });
        } catch (err) {
          addToast({
            type: "error",
            title: "Error updating evaluation",
          });
          throw err;
        }
      },
      loadMode: "raw",
      cacheRawData: true,
      paginate: true,
    });
  }, [addToast, path, user.userId]);

  const areaTypes = useMemo(() => {
    return new CustomStore({
      paginate: true,
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await api.get("api/area-types");
        return response.data;
      },
    });
  }, []);

  const evaluationStatuses = useMemo(() => {
    return new CustomStore({
      key: "ID",
      loadMode: "raw",
      paginate: true,
      load: async () => {
        const response = await api.get("api/evaluation-status/");
        return response.data;
      },
    });
  }, []);

  const cycleSource = useMemo(() => {
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      load: async () => {
        const { data } = await api.get("/api/cycles");
        const cyclesWithDisplayName = data.map((cycle) => ({
          ...cycle,
          displayName: `(${cycle.areaType?.description}) ${cycle.period}Q - ${cycle.year}`,
        }));
        return cyclesWithDisplayName;
      },
      paginate: true,
    });
  }, []);

  const handleRowClick = useCallback(
    (e) => {
      if (
        path === "myevaluations" &&
        e.data?.idEvaluationStatus !== 4 &&
        e.data?.idEvaluationStatus !== 5 &&
        e.data?.idEvaluationStatus !== 4 &&
        e.data?.idEvaluationStatus !== 5 &&
        e.data?.idEvaluator !== user.userId &&
        e.data?.idValidator !== user.userId &&
        e.data?.idPartner !== user.userId &&
        !isSenior
      ) {
        return;
      }
      if (e.data) {
        history.push(`${e?.data?.id}`);
      }
    },
    [history, isSenior, path, user.userId]
  );

  const getFilteredCycles = useCallback(
    (options) => {
      return {
        store: cycleSource,
        filter: options.data
          ? ["idAreaType", "=", options.data.idAreaType]
          : null,
      };
    },
    [cycleSource]
  );

  const handleEditingStart = useCallback((e) => {
    if (e) {
      setSelectedRowData(e);
    }
    setPopupVisible(true);
  }, []);

  const handleEditProject = useCallback((data) => {
    setEvaluationEditProjectId(data.id);
    setEvaluationConfirmedProject(data.isProjectConfirmed);
    setOpenEditProject(true);
  }, []);

  const checkIsSenior = useCallback(
    (idAreaType) => {
      if (idAreaType === undefined) return false;

      const isWeightedAverage = areaTypes.__rawData.find(
        (x) => x.id === idAreaType
      ).isWeightedAverage;
      return isWeightedAverage;
    },
    [areaTypes]
  );

  return (
    <Container>
      <DataGrid
        dataSource={dataSource}
        allowColumnResizing
        columnAutoWidth
        width="100%"
        height="100%"
        onToolbarPreparing={(e) => {
          if (path === "allevaluations" && isAdmin) {
            e.toolbarOptions.items.unshift({
              location: "after",
              widget: "dxButton",
              options: {
                hint: "Add Evaluation",
                icon: "plus",
                onClick: () => {
                  setSelectedRowData({});
                  handleEditingStart();
                },
              },
            });
          }
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            options: {
              text: "Clear Filters",
              elementAttr: { class: "clear-filter" },
              icon: "filter",
              onClick: () => {
                e.component.clearFilter();
              },
            },
          });
        }}
        onContentReady={(e) => {
          var filterExpr = e.component.getCombinedFilter();
          let showEvaluatorValidatorPartner = true;
          let showMentor = true;
          if (Array.isArray(filterExpr) && filterExpr.length >= 3) {
            let filterOperator = filterExpr[1];
            let filterValue = filterExpr[2];
            let columnIndex = filterExpr.columnIndex;

            const areaTypeColumnIndex = 1;
            const cycleColumnIndex = 2;

            if (filterOperator === "=") {
              if (columnIndex === areaTypeColumnIndex) {
                if (filterValue === 1 || filterValue === 2) {
                  showEvaluatorValidatorPartner = true;
                  showMentor = false;
                } else {
                  showEvaluatorValidatorPartner = false;
                  showMentor = true;
                }
              } else if (columnIndex === cycleColumnIndex) {
                const cycle =
                  dataSource._items[0].cycle?.areaType?.isWeightedAverage;

                if (!cycle) {
                  showEvaluatorValidatorPartner = true;
                  showMentor = false;
                } else {
                  showEvaluatorValidatorPartner = false;
                  showMentor = true;
                }
              }
            }
          }
          e.component.columnOption(
            "idEvaluator",
            "visible",
            showEvaluatorValidatorPartner
          );
          e.component.columnOption(
            "idValidator",
            "visible",
            showEvaluatorValidatorPartner
          );
          e.component.columnOption(
            "idPartner",
            "visible",
            showEvaluatorValidatorPartner
          );
          e.component.columnOption("mentor", "visible", showMentor);
        }}
        headerFilter={{ visible: true, allowSearch: true }}
        sorting={{ mode: "multiple" }}
        onRowClick={(e) => handleRowClick(e)}
      >
        <Column
          dataField="idEvaluationStatus"
          caption="Status"
          dataType="number"
          cellRender={(e) => (
            <StatusCell status={e.data?.evaluationStatus?.id}>
              <PhotoCell data={e.data?.professional} />
              {e.data?.evaluationStatus?.description}
            </StatusCell>
          )}
          width={230}
          alignment="left"
        >
          <Lookup
            dataSource={evaluationStatuses}
            displayExpr="description"
            valueExpr="id"
          />
        </Column>

        <Column
          dataField="idAreaType"
          dataType="number"
          caption="Area Type"
          editorType="dxSelectBox"
          setCellValue={(newData, value) => {
            newData.idCycle = null;
            newData.isProjectConfirmed = false;
            newData.idAreaType = value;
          }}
        >
          <Lookup
            dataSource={areaTypes}
            valueExpr="id"
            displayExpr="description"
          />
        </Column>

        <Column
          dataField="idCycle"
          dataType="number"
          caption="Cycle"
          editorType="dxSelectBox"
          cellRender={(e) => {
            return ` ${e.data?.cycle?.period}Q — ${e.data?.cycle?.year} `;
          }}
        >
          <Lookup
            dataSource={(e) => getFilteredCycles(e)}
            displayExpr="displayName"
            valueExpr="id"
            placeholder="Select cycle"
            minSearchLength={2}
            required={true}
            showDataBeforeSearch={true}
            searchEnabled={true}
          />
        </Column>

        <Column
          dataField="idProfessional"
          caption="Professional"
          alignment="left"
          dataType="number"
          width={130}
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          dataField="idPractice"
          name="Pratice"
          caption="Practice"
          dataType="number"
        >
          <Lookup
            dataSource={master.practices}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          dataField="idOffice"
          caption="Office"
          dataType="number"
          cellRender={(e) => (
            <OfficeIcon
              office={e.data.office?.name ? e.data.office?.name : null}
              className="office-icon"
            />
          )}
        >
          <Lookup
            dataSource={master.offices}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>

        <Column
          dataField="idProject"
          caption="Project"
          dataType="number"
          editorType="dxSelectBox"
          cellRender={(e) => e.data.project?.projectCode ?? "—"}
        >
          <Lookup
            dataSource={master.projects}
            editorType="dxSelectBox"
            displayExpr={(item) =>
              item && item.ProjectCode && item.ProjectName
                ? `${item.ProjectCode} - ${item.ProjectName}`
                : "—"
            }
            valueExpr="ID"
          />
        </Column>

        <Column
          // visible={!isSeniorFiltered || isNotSeniorFiltered}
          dataField="idEvaluator"
          caption="Evaluator"
          dataType="number"
          cellRender={(e) =>
            checkIsSenior(e.row.data.idAreaType) ? (
              "—"
            ) : (
              <PhotoCell data={e.data?.evaluator} />
            )
          }
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          // visible={!isSeniorFiltered || isNotSeniorFiltered}
          dataField="idValidator"
          caption="Validator"
          dataType="number"
          cellRender={(e) =>
            checkIsSenior(e.row.data.idAreaType) ? (
              "—"
            ) : (
              <PhotoCell data={e.data?.validator} />
            )
          }
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          // visible={!isSeniorFiltered || isNotSeniorFiltered}
          dataField="idPartner"
          name="partner"
          caption="Partner"
          dataType="number"
          cellRender={(e) =>
            !e.data?.areaType?.isWeightedAverage ? (
              <PhotoCell data={e.data?.partner} />
            ) : null
          }
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          // visible={
          //   isSeniorFiltered || (!isSeniorFiltered && !isNotSeniorFiltered)
          // }
          dataField="idPartner"
          name="mentor"
          caption="Mentor"
          dataType="number"
          cellRender={(e) =>
            e.data?.areaType?.isWeightedAverage ? (
              <PhotoCell data={e.data?.partner} />
            ) : null
          }
        >
          <Lookup
            dataSource={master.professionals}
            displayExpr="Name"
            valueExpr="ID"
          />
        </Column>
        <Column
          dataField={
            path === "myevaluations" ? "displayedAverage" : "totalAverage"
          }
          dataType="number"
          caption="Total Average"
          alignment="left"
          cellRender={(e) => {
            const enrichedData =
              (e.data?.idProfessional === user.userId &&
                e.data?.idEvaluationStatus !== 4 &&
                e.data?.idEvaluationStatus !== 5) ||
              (isSales && e.data?.idProfessional !== user.userId) ||
              (isSales &&
                e.data?.idProfessional === user.userId &&
                e.data?.idEvaluationStatus !== 4 &&
                e.data?.idEvaluationStatus !== 5)
                ? "-"
                : e.value?.toFixed(2);

            return <GradeCell>{enrichedData}</GradeCell>;
          }}
          format={{ precision: 2, type: "fixedPoint" }}
        />

        <Column
          dataField="isProjectConfirmed"
          caption="Is Project Confirmed"
          dataType="boolean"
          editorType="dxCheckBox"
          visible={false}
        />

        <Column
          type="buttons"
          buttons={[
            {
              hint: "Edit",
              icon: "edit",
              visible: path === "allevaluations" && isAdmin,
              onClick: (e) => {
                handleEditingStart(e.row.data);
              },
            },
            {
              hint: "View Project",
              icon: "folder",
              visible: (e) =>
                (path === "myevaluations" || path === "allevaluations") &&
                (isAdmin || isSenior) &&
                checkIsSenior(
                  e.row.data.idAreaType ||
                    e.row.data.cycle.idAreaType ||
                    undefined
                ),
              onClick: (e) => {
                handleEditProject(e.row.data);
              },
            },
          ]}
        />
      </DataGrid>
      <CustomEditInsertPopup
        evaluationSource={dataSource}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
        selectedRowData={selectedRowData}
        master={master}
        areaTypes={areaTypes}
        evaluationStatuses={evaluationStatuses}
        getFilteredCycles={getFilteredCycles}
      />
      {openEditProject && (
        <DialogEditProject
          evaluationSource={dataSource}
          open={openEditProject}
          master={master}
          handleClose={() => setOpenEditProject(false)}
          evaluationId={evaluationEditProjectId}
          isProjectConfirmed={evaluationConfirmedProject}
        />
      )}
    </Container>
  );
}
