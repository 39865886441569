import Tooltip from "@material-ui/core/Tooltip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GrContract, GrExpand, GrFormClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import NoIcon from "../../../assets/images/no-icon.svg";
import pdsGroups from "../../../config/pdsGroups";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import DialogProjectStatus from "../../../pages/Evaluation/DialogChangeStatus";
import Button from "../../Button";
import DownloadPDFBtn from "../../EvaluationPDF";
import OfficeIcon from "../../OfficeIcons";
import { TopMenu } from "./styles";
import api from "../../../services/api";
import { FiLock, FiUnlock } from "react-icons/fi";
import DialogCriteriaInfo from "./DialogCriteriaInfo";
import { IconButton } from "@material-ui/core";

export default function SidePanelHeader({
  evaluation,
  pillars,
  path,
  setIsSidePanelOpen,
  isSidePanelFullScreen,
  setIsSidePanelFullScreen,
  isLoadingGrades,
  isEvaluationSenior,
  handleChangeIsLocked,
}) {
  const { hasScope, user } = useAuth();
  const { addToast } = useToast();
  const [canDownloadPDF, setCanDownloadPDF] = useState(false);
  const [canChangeIsLocked, setCanChangeIsLocked] = useState(false);
  const [changeIsLocked, setChangeIsLocked] = useState(evaluation.isLocked);
  const isSales = useMemo(
    () => hasScope(pdsGroups.Sales) && !hasScope(pdsGroups.Corporate),
    [hasScope]
  );
  const checkIfUserCanDownloadPDF = useCallback(() => {
    if (
      evaluation?.evaluationStatus?.id === 1 &&
      (hasScope(pdsGroups.Admin) || user.userId === evaluation?.idEvaluator)
    ) {
      setCanDownloadPDF(true);
    } else if (
      evaluation?.evaluationStatus?.id === 2 &&
      (hasScope(pdsGroups.Admin) || user.userId === evaluation?.idValidator)
    )
      setCanDownloadPDF(true);
    else if (
      evaluation?.evaluationStatus?.id === 3 &&
      (hasScope(pdsGroups.Admin) || user.userId === evaluation?.idEvaluator)
    )
      setCanDownloadPDF(true);
    else if (
      evaluation?.evaluationStatus?.id === 4 &&
      (hasScope(pdsGroups.Admin) || user.userId === evaluation?.idEvaluator)
    )
      setCanDownloadPDF(true);
    else if (evaluation?.evaluationStatus?.id === 5) setCanDownloadPDF(true);
    else if (evaluation?.idPartner === user.userId) setCanDownloadPDF(true);
    if (isSales) {
      setCanDownloadPDF(false);
    }
  }, [evaluation, hasScope, isSales, user.userId]);
  const [canChangeStatus, setCanChangeStatus] = useState(false);
  const checkIfUserCanChangeStatus = useCallback(() => {
    if (
      evaluation?.evaluationStatus?.id === 1 &&
      (hasScope(pdsGroups.Admin) || user.userId === evaluation?.idEvaluator)
    ) {
      setCanChangeStatus(true);
    } else if (
      evaluation?.evaluationStatus?.id === 2 &&
      (hasScope(pdsGroups.Admin) || user.userId === evaluation?.idValidator)
    )
      setCanChangeStatus(true);
    else if (
      evaluation?.evaluationStatus?.id === 3 &&
      (hasScope(pdsGroups.Admin) || user.userId === evaluation?.idEvaluator)
    )
      setCanChangeStatus(true);
    else if (
      evaluation?.evaluationStatus?.id === 4 &&
      (hasScope(pdsGroups.Admin) ||
        user.userId === evaluation?.idEvaluator ||
        user.userId === evaluation?.idProfessional)
    )
      setCanChangeStatus(true);
    else if (evaluation?.evaluationStatus?.id === 5) setCanChangeStatus(true);
    else if (evaluation?.idPartner === user.userId) setCanChangeStatus(true);
    if (
      isSales &&
      (evaluation?.idProfessional !== user.userId ||
        (evaluation?.idProfessional === user.userId &&
          evaluation?.evaluationStatus?.id !== 4 &&
          evaluation?.evaluationStatus?.id !== 5))
    ) {
      setCanChangeStatus(false);
    }
  }, [evaluation, hasScope, isSales, user.userId]);
  const checkIfUserCanChangeIsLocked = useCallback(() => {
    if (hasScope(pdsGroups.Admin)) {
      setCanChangeIsLocked(true);
    } else {
      setCanChangeIsLocked(false);
    }
  }, [hasScope]);
  const onLockToggle = (newValue) => {
    handleChangeIsLocked(newValue);
  };

  useEffect(() => {
    checkIfUserCanDownloadPDF();
  }, [checkIfUserCanDownloadPDF]);
  useEffect(() => {
    checkIfUserCanChangeStatus();
  }, [checkIfUserCanChangeStatus]);
  useEffect(() => {
    checkIfUserCanChangeIsLocked();
  }, [checkIfUserCanChangeIsLocked]);

  const history = useHistory();

  const evaluators = [
    {
      key: "evaluator",
      label: "Evaluator",
      aplicable:
        isEvaluationSenior && evaluation.idAreaType === 5 ? false : true,
    },
    {
      key: "validator",
      label: "Validator",
      aplicable:
        isEvaluationSenior && evaluation.idAreaType === 5 ? false : true,
    },
    {
      key: "partner",
      label: evaluation.idAreaType === 5 ? "Mentor" : "Partner",
      aplicable: true,
    },
  ];

  return (
    <>
      <TopMenu status={evaluation?.evaluationStatus?.id}>
        <div>
          <div className="evaluators">
            {evaluators?.map(({ key, label, aplicable }) =>
              aplicable === true
                ? evaluation &&
                  evaluation[key] && (
                    <div key={key}>
                      <Tooltip title={evaluation[key]?.name} placement="bottom">
                        <img
                          src={
                            evaluation[key]?.login
                              ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${evaluation[key]?.login}_192_192.png`
                              : NoIcon
                          }
                          alt={evaluation[key]?.name}
                        />
                      </Tooltip>
                      {label}
                    </div>
                  )
                : null
            )}
          </div>
          <div className="status">
            {evaluation?.evaluationStatus?.description}
          </div>
          <div className="tools">
            <div onClick={() => setIsSidePanelFullScreen((x) => !x)}>
              {!isSidePanelFullScreen ? (
                <GrExpand size={12} />
              ) : (
                <GrContract size={12} />
              )}
            </div>
            <div
              onClick={() => {
                history.push("/" + path + "/");
                setIsSidePanelFullScreen(false);
                setIsSidePanelOpen(false);
              }}
            >
              <GrFormClose size={20} />
            </div>
          </div>
        </div>
        <div className="profile">
          <div>
            <img
              className="professional"
              src={
                evaluation?.professional?.login
                  ? `https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${evaluation.professional?.login}_192_192.png`
                  : NoIcon
              }
              alt={evaluation?.professional?.name}
            />
            <div>
              <div>
                <h2>{evaluation?.professional?.name}</h2>
                <div className="profile-info">
                  <h3>
                    <b>{evaluation?.professional?.jobTitle.name}</b> |{" "}
                    {evaluation?.professional?.practices[0]?.name}
                  </h3>

                  <OfficeIcon
                    className="office-icon"
                    office={evaluation?.professional?.offices[0]?.name.toLowerCase()}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="buttons-wrapper">
            {canChangeIsLocked && (
              <Tooltip
                title={
                  changeIsLocked
                    ? "Click to unlock this evaluation"
                    : "Click to lock this evaluation"
                }
                placement="top"
              >
                <div>
                  <IconButton
                    className="lock-btn"
                    onClick={() => {
                      setChangeIsLocked((prev) => {
                        const newValue = !prev;
                        onLockToggle(newValue);
                        return newValue;
                      });
                    }}
                  >
                    {changeIsLocked ? (
                      <FiLock size={14} color="red" />
                    ) : (
                      <FiUnlock size={14} color="green" />
                    )}
                  </IconButton>
                </div>
              </Tooltip>
            )}
            {canDownloadPDF && (
              <DownloadPDFBtn
                pillars={pillars}
                evaluation={evaluation}
                isLoadingGrades={isLoadingGrades}
              />
            )}
            {canChangeStatus && <DialogProjectStatus evaluation={evaluation} />}
          </div>
        </div>
      </TopMenu>
    </>
  );
}
