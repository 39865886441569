import React, { useCallback, useState, useEffect, useMemo } from "react";

import { WhisperSpinner } from "react-spinners-kit";
import CustomStore from "devextreme/data/custom_store";
import { SelectBox, CheckBox } from "devextreme-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import ProfessionalLookup from "../../../../components/ProfessionalLookup";
import Button from "../../../../components/Button";
import TextArea from "../../../../components/TextArea";
import Numeric from "../../../../components/EvaluationNumeric";
import api from "../../../../services/api";
import master from "../../../../services/master";

import { Label, Fieldset, LoadingContainer } from "./styles";

import { useToast } from "../../../../hooks/toast";
import { useAuth } from "../../../../hooks/auth";
import pdsGroups from "../../../../config/pdsGroups";

export default function DialogSeniorProject({
  evaluationId,
  id,
  open,
  handleClose,
  onChanged,
  idAreaType,
}) {
  const [professionalSeniorSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await master.get("master/professionals", {
          params: {
            where: {
              IsActive: true,
              JobTitle: "seniors",
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });

  const [professionalSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await master.get("master/professionals", {
          params: {
            where: {
              IsActive: true,
            },
          },
        });
        return response.data;
      },
    }),
    paginate: true,
  });

  const [projectSource] = useState({
    store: new CustomStore({
      key: "ID",
      loadMode: "raw",
      load: async () => {
        const response = await master.get("master/projects");

        const projects = response.data.map((project) => ({
          ...project,
          displayName: `${project.ProjectCode || "No code"} - ${
            project.ProjectName
          } - ${project.Client && project.Client.Name}`,
        }));

        return projects;
      },
    }),
    paginate: true,
  });

  const { hasScope, user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [fieldText, setFieldText] = useState("");
  const [fieldText2, setFieldText2] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [fieldValue2, setFieldValue2] = useState("");
  const [workDays, setWorkDays] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isApplicable, setIsApplicable] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [project, setProject] = useState("");
  const [idEvaluator, setIdEvaluator] = useState(null);
  const [statusId, setStatusId] = useState(0);

  const loadEvaluation = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `api/evaluations/${evaluationId}/senior-projects/${id}`
    );

    setFieldText(data.fieldText);
    setFieldText2(data.fieldText2);
    setFieldValue(data.fieldValue);
    setFieldValue2(data.fieldValue2);
    setWorkDays(data.workDays);
    setIsActive(data.isActive);
    setIsApplicable(data.isApplicable);
    setIsPartner(data.isPartner);
    setProject(data.idProject);
    setIdEvaluator(data.idEvaluator);
    setStatusId(data.evaluation.idEvaluationStatus);

    setLoading(false);
  }, [evaluationId, id]);

  useEffect(() => {
    loadEvaluation();
  }, [loadEvaluation]);

  const handleSubmit = useCallback(
    async (key, value) => {
      try {
        await api.put(`api/evaluations/${evaluationId}/senior-projects/${id}`, {
          [key]: value,
        });

        addToast({
          type: "success",
          title: "Saved",
        });

        onChanged();
      } catch (err) {
        addToast({
          type: "error",
          title: "Ops",
          description: "Ops... Something went wrong on edit",
        });
      }

      setLoading(false);
    },
    [evaluationId, id, addToast, onChanged]
  );

  const grades = useMemo(
    () => ["", 0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3],
    []
  );

  const statusNames = useMemo(
    () => ({
      Draft: 1,
      WaitingDiscussion: 3,
      Completed: 5,
      Excluded: 6,
    }),
    []
  );

  const allowEdit = useMemo(() => {
    return (
      hasScope([pdsGroups.Admin]) ||
      (statusId === statusNames.Draft && idEvaluator === user.userId)
    );
  }, [hasScope, idEvaluator, statusId, statusNames, user]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Edit</DialogTitle>
      <DialogContent>
        {loading && (
          <LoadingContainer>
            <WhisperSpinner
              size={20}
              color="#8b0304"
              backColor="#8b0304"
              frontColor="#fff"
            />
          </LoadingContainer>
        )}

        {!loading && (
          <>
            {hasScope([pdsGroups.Admin]) && (
              <>
                <Fieldset>
                  <Label>Project</Label>
                  <SelectBox
                    dataSource={projectSource}
                    displayExpr="displayName"
                    valueExpr="ID"
                    placeholder="Select project"
                    searchExpr={["ProjectName", "ProjectCode"]}
                    minSearchLength={3}
                    required
                    showDataBeforeSearch
                    searchEnabled
                    value={project}
                    onValueChanged={(e) => {
                      setProject(e.value);
                      handleSubmit("idProject", e.value);
                    }}
                    readOnly={!allowEdit}
                  />
                </Fieldset>
                <Fieldset>
                  <Label>
                    {isPartner ? "Partner" : "Analyst | Consultant"}
                  </Label>
                  <SelectBox
                    dataSource={
                      isPartner ? professionalSeniorSource : professionalSource
                    }
                    displayExpr="Name"
                    valueExpr="ID"
                    placeholder="Select a professional"
                    itemRender={(data) => (
                      <ProfessionalLookup
                        name={data.Name}
                        login={data.Login}
                        jobtitle={data.JobTitle.Name}
                      />
                    )}
                    searchExpr={["Name", "JobTitle.Name"]}
                    minSearchLength={3}
                    showDataBeforeSearch
                    searchEnabled
                    value={idEvaluator}
                    onValueChanged={(e) => {
                      setIdEvaluator(e.value);
                      handleSubmit("idEvaluator", e.value);
                    }}
                    readOnly={!allowEdit}
                  />
                </Fieldset>

                <Fieldset>
                  <Label className="label">Work days</Label>
                  <Numeric
                    defaultValue={workDays}
                    onChanged={(value) => {
                      setWorkDays(value);
                      handleSubmit("workDays", value);
                    }}
                    style={{ backgroundColor: "#efefef", width: "100%" }}
                    readOnly={!allowEdit}
                  />
                </Fieldset>

                <Fieldset>
                  <CheckBox
                    value={isActive}
                    onValueChanged={(e) => {
                      setIsActive(e.value);
                      handleSubmit("isActive", e.value);
                    }}
                    className="checkbox"
                    style={{ marginRight: 10 }}
                    readOnly={!allowEdit}
                  />
                  <Label className="label">Considered</Label>
                </Fieldset>

                <hr />
              </>
            )}

            {!isPartner && (
              <Fieldset>
                <CheckBox
                  value={isApplicable}
                  onValueChanged={(e) => {
                    setIsApplicable(e.value);
                    handleSubmit("isApplicable", e.value);
                  }}
                  className="checkbox"
                  style={{ marginRight: 10 }}
                  readOnly={!allowEdit}
                />
                <Label className="label">
                  Do you feel comfortable to evaluate this professional? (If you
                  don't, uncheck the box)
                </Label>
              </Fieldset>
            )}

            {isPartner && (
              <Fieldset>
                <Label>
                  {idAreaType === 10 ||
                  idAreaType === 11 ||
                  idAreaType === 12 ||
                  idAreaType === 13 ||
                  idAreaType === 6 ||
                  idAreaType === 5 ||
                  idAreaType === 4 ||
                  idAreaType === 9 ||
                  idAreaType === 3
                    ? "OVERALL"
                    : "BUILDING A RELATIONSHIP"}{" "}
                  Grade
                </Label>
                <SelectBox
                  dataSource={grades}
                  placeholder="Select a grade"
                  value={fieldValue}
                  onValueChanged={(e) => {
                    setFieldValue(e.value);
                    handleSubmit("fieldValue", e.value);
                  }}
                  readOnly={!allowEdit}
                />
              </Fieldset>
            )}

            <Fieldset>
              <Label>
                {idAreaType === 10 || idAreaType === 11
                  ? "STRONG POINTS"
                  : isPartner
                  ? "BUILDING A RELATIONSHIP Key message"
                  : "Has the professional contributed in your professional development during this project?\n" +
                    "Has this professional invested time and provided tools and environment on your development?\n" +
                    "Please, give examples."}
              </Label>
              <TextArea
                value={fieldText}
                onChanged={(value) => {
                  setFieldText(value);
                  handleSubmit("fieldText", value);
                }}
                style={{ height: 200, backgroundColor: "#efefef" }}
                disabled={!allowEdit}
              />
            </Fieldset>

            {isPartner && idAreaType !== 10 && idAreaType !== 11 && (
              <Fieldset>
                <Label>PROJECT Grade</Label>
                <SelectBox
                  dataSource={grades}
                  placeholder="Select a grade"
                  value={fieldValue2}
                  onValueChanged={(e) => {
                    setFieldValue2(e.value);
                    handleSubmit("fieldValue2", e.value);
                  }}
                  readOnly={!allowEdit}
                />
              </Fieldset>
            )}

            <Fieldset>
              <Label>
                {idAreaType === 10 || idAreaType === 11
                  ? "DEVELOPMENT POINTS"
                  : isPartner
                  ? "PROJECT Key message"
                  : "What are the development points of this professional in this pillar (Professional Development)? (type N/A if not applicable)"}
              </Label>
              <TextArea
                value={fieldText2}
                onChanged={(value) => {
                  setFieldText2(value);
                  handleSubmit("fieldText2", value);
                }}
                style={{ height: 200, backgroundColor: "#efefef" }}
                disabled={!allowEdit}
              />
            </Fieldset>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
